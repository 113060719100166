import React from 'react';
import routes from '../../../../utils/routes';
import { InfoCard } from '../../../common/components';

function OrderOnlinePaymentInfoCard({ webshopPaymentId }) {
  const rows = [
    {
      label: 'Public Payment ID',
      value: routes.payment.createLink(webshopPaymentId, webshopPaymentId),
    },
  ];

  return <InfoCard title="Online payment" rows={rows} />;
}

export default OrderOnlinePaymentInfoCard;
