import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';

function InfoCardRow({ label, value }) {
  return (
    <Grid container>
      <Grid
        item
        xs={6}
        style={{
          display: label === 'Event ID' && 'flex',
          alignItems: label === 'Event ID' && 'center',
        }}
      >
        <Typography variant="body1">{label}:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">{value}</Typography>
      </Grid>
    </Grid>
  );
}

InfoCardRow.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.node,
};

export default InfoCardRow;
