import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { format, lastDayOfMonth } from 'date-fns';
import { Box, Grid, Typography } from '@material-ui/core';
import { FillerSpinner } from '../../../lib/components';
import { useGetPaymentsStatistics } from '../../hooks/useGetPaymentsStatistics';
import { Bar } from 'react-chartjs-2';
import { Alert, AlertTitle } from '@material-ui/lab';
import { PaymentStatisticsTable, PaymentStatusSelect } from '..';

function PaymentStatistics() {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [status, setStatus] = useState('PAYMENT_SUCCEEDED');

  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const pathname = history.location.pathname;

  const values = {
    from: fromDate,
    to: toDate,
    status,
  };
  const { data, isLoading, isSuccess, isError, error } = useGetPaymentsStatistics(values);
  const { payments, summedAmount } = data || {};

  const today = new Date();
  const firstDayOfCurrentMonth = format(today, 'yyyy-MM-01');
  const lastDayOfCurrentMonth = format(lastDayOfMonth(today), 'yyyy-MM-dd');

  useEffect(() => {
    const fromDateFromUrl = searchParams.get('from');
    const toDateFromUrl = searchParams.get('to');
    const statusFromUrl = searchParams.get('status');

    if (fromDateFromUrl) {
      setFromDate(fromDateFromUrl);
    } else {
      setFromDate(firstDayOfCurrentMonth);
    }
    if (toDateFromUrl) {
      setToDate(toDateFromUrl);
    } else {
      setToDate(lastDayOfCurrentMonth);
    }
    if (statusFromUrl) {
      setStatus(statusFromUrl);
    }
  }, [searchParams, firstDayOfCurrentMonth, lastDayOfCurrentMonth]);

  const handleFromDateChange = e => {
    const date = e.target.value;
    setFromDate(date);
    const formatedDate = format(new Date(date), 'yyyy-MM-dd');
    searchParams.set('from', formatedDate);
    history.push({ pathname, search: searchParams.toString() });
  };
  const handleToDateChange = e => {
    const date = e.target.value;
    setToDate(date);
    const formatedDate = format(new Date(date), 'yyyy-MM-dd');
    searchParams.set('to', formatedDate);
    history.push({ pathname, search: searchParams.toString() });
  };

  let labels = [];
  let datasets;
  if (isSuccess && !status) {
    const parsedData = {};
    const allStatuses = new Set();
    payments.forEach(item => {
      const { amount, _id } = item;
      const { date, status } = _id;

      if (!parsedData[date]) {
        parsedData[date] = {};
      }

      parsedData[date][status] = amount;
      allStatuses.add(status);
    });
    const chartColor = status => {
      const color =
        status === 'CANCELLED_PAYMENT'
          ? '#0077B6'
          : status === 'TRANSACTION_STARTED'
          ? '#0096C7'
          : status === 'PAYMENT_SUCCEEDED'
          ? '#00B4D8'
          : status === 'INVALID_SIGNATURE'
          ? '#48CAE4'
          : status === 'REFUNDED'
          ? '#90E0EF'
          : status === 'TIMEOUTED'
          ? '#ADE8F4'
          : '#FFF';
      return color;
    };
    labels = Object.keys(parsedData);
    datasets = Array.from(allStatuses).map(status => ({
      label: status,
      data: labels.map(date => parsedData[date][status] || 0),
      backgroundColor: chartColor(status),
      borderColor: chartColor(status),
      borderWidth: 0.5,
    }));
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          let value = status
            ? data.datasets[0].data[tooltipItem.index]
            : data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          const dataLabel = status
            ? data.datasets[0].label
            : data.datasets[tooltipItem.datasetIndex].label;
          const currentPayment = payments.find(
            payment => payment._id.status === dataLabel && payment.amount === value,
          );
          const numberOfPayments = status
            ? payments[tooltipItem.index].numberOfItems
            : currentPayment.numberOfItems;
          let formattedValue = value;
          if (parseInt(value) >= 1000) {
            formattedValue = value
              .toString()
              .split(/(?=(?:...)*$)/)
              .join('.');
          }
          return `${dataLabel}: ${formattedValue} Ft (qty: ${numberOfPayments})`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            userCallback: function(value) {
              return value
                .toString()
                .split(/(?=(?:...)*$)/)
                .join('.');
            },
          },
        },
      ],
    },
    legend: status ? { display: false } : { labels: { fontSize: 15 } },
  };

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '95%',
          overflow: 'hidden',
        }}
      >
        <Box
          display={'flex'}
          justifyContent={'center'}
          flexWrap={'wrap'}
          alignItems={'center'}
          alignContent={'center'}
          style={{ marginBottom: '10px', marginTop: '10px', flexShrink: 0 }}
        >
          <Typography style={{ margin: '0px 4px', fontWeight: 'bold' }}>From:</Typography>
          <input
            type="date"
            placeholder="YYYY-MM-DD"
            value={fromDate}
            onChange={handleFromDateChange}
            style={{ margin: '0px 3px', maxWidth: '150px', backgroundColor: 'transparent' }}
          />
          <Typography style={{ margin: '0px 4px', fontWeight: 'bold' }}>To:</Typography>
          <input
            type="date"
            placeholder="YYYY-MM-DD"
            value={toDate}
            onChange={handleToDateChange}
            style={{ margin: '0px 3px', maxWidth: '150px', backgroundColor: 'transparent' }}
          />
          <PaymentStatusSelect status={status} setStatus={setStatus} isStatistics={true} />
          <button
            onClick={() => {
              setFromDate(firstDayOfCurrentMonth);
              setToDate(lastDayOfCurrentMonth);
              setStatus('');
              searchParams.delete('from');
              searchParams.delete('to');
              searchParams.delete('status');
              history.push({ pathname, search: searchParams.toString() });
            }}
            style={{ padding: '2px', margin: '2px' }}
          >
            Clear
          </button>
        </Box>
        {isLoading && (
          <Grid style={{ padding: '8px', margin: '8px' }}>
            <FillerSpinner />
          </Grid>
        )}
        {isError && (
          <Alert severity="error" style={{ padding: '4px', margin: '4px' }}>
            <AlertTitle>Error</AlertTitle>
            <Typography>An error occured during data loading: {error.message}</Typography>
          </Alert>
        )}
        <Box style={{ flexGrow: 1, overflow: 'hidden'}}>
          {isSuccess &&
            (payments.length > 0 ? (
              status ? (
                <Bar
                  data={{
                    labels: payments.map(date => {
                      return date._id;
                    }),
                    datasets: [
                      {
                        label: 'amount (Ft)',
                        data: payments.map(amount => {
                          return amount.amount;
                        }),
                        backgroundColor: '#3f51b5',
                        borderColor: '#3f51b5',
                        borderWidth: 0.5,
                      },
                    ],
                  }}
                  options={options}
                  height={100}
                />
              ) : (
                <Bar
                  data={{
                    labels: labels,
                    datasets: datasets,
                  }}
                  options={options}
                  height={100}
                />
              )
            ) : (
              <Alert severity="warning" style={{ padding: '4px', margin: '4px' }}>
                <AlertTitle>Warning</AlertTitle>
                <Typography>
                  There is no payment with the given dates or status to display!
                </Typography>
              </Alert>
            ))}
        </Box>
      </Box>
      {isSuccess && (
        <Grid style={{ display: 'flex', flexDirection: 'column', marginTop: '28px' }}>
          <hr style={{ width: '60%', alignSelf: 'center' }} />
          <Typography
            style={{ textAlign: 'center', fontSize: '18px', padding: '8px', margin: '18px' }}
          >
            Summed amount of online payments based on the statuses{' '}
            {summedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} Ft
          </Typography>
        </Grid>
      )}
      <PaymentStatisticsTable status={status} from={fromDate} to={toDate} />
    </>
  );
}

export default PaymentStatistics;
