import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useState } from 'react';
import { format } from 'date-fns';
import { LimitedBackdrop } from '../common/components/LimitedBackdrop/LimitedBackdrop';

function CheckingSearchBarTable({ isError, isLoading, isSuccess, data }) {
  const [checkedElbCard, setCheckedElbCard] = useState('');
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ELB Card Number</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell>Online ELB Card Number</TableCell>
            <TableCell>Expiration Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="center">Customer</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isError && (
            <TableRow>
              <TableCell colSpan={7}>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  <Typography>Something went wrong!</Typography>
                </Alert>
              </TableCell>
            </TableRow>
          )}
          {isLoading && (
            <TableRow
              style={{
                position: 'relative',
                height: '110px',
              }}
            >
              <TableCell colSpan={7}>
                <LimitedBackdrop open={isLoading} style={{ padding: '8px' }}>
                  <CircularProgress color="primary" />
                </LimitedBackdrop>
              </TableCell>
            </TableRow>
          )}
          {data?.length > 0 ? (
            data.map(checking => {
              const {
                _id,
                customer,
                type,
                status,
                cardNumber,
                onlineCardNumber,
                expirationDate,
                elbCardProductSnapshot,
              } = checking;
              const { email, fullName, profilePicture } = customer?.[0];
              const { name: typeName } = type?.[0];
              const { name: productTypeName } = elbCardProductSnapshot?.[0] || {};

              return (
                <TableRow key={_id}>
                  <TableCell>{cardNumber}</TableCell>
                  <TableCell>
                    {typeName === 'UNUSED_Product_related_type' ? productTypeName : typeName}
                  </TableCell>
                  <TableCell>{onlineCardNumber}</TableCell>
                  <TableCell>
                    {expirationDate && format(new Date(expirationDate), 'yyyy-MM-dd HH:mm')}
                  </TableCell>
                  <TableCell>{status}</TableCell>
                  <TableCell>
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Box>
                        <Typography>{fullName}</Typography>
                        <Typography>{email}</Typography>
                      </Box>
                      {profilePicture?.url ? (
                        <img src={profilePicture.url} alt={fullName} width="45px" />
                      ) : (
                        ''
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => setCheckedElbCard(_id)}
                      color={checkedElbCard === _id ? 'primary' : 'default'}
                      fullWidth
                    >
                      {checkedElbCard === _id ? 'Checked' : 'Use ELB card for checking'}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={7}>
                <Alert severity="warning">
                  <AlertTitle>Not found</AlertTitle>
                  <Typography>No ELB card was found with provided keyword.</Typography>
                </Alert>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CheckingSearchBarTable;
