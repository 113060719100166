import React from 'react';
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import useGetNotes from './useGetNotes.hook';
import { Alert, AlertTitle } from '@material-ui/lab';
import { format } from 'date-fns';
import CreateOrEditNote from './CreateOrEditNote.component';
import { useGetCurrentUser } from '../auth/hooks';
import { LimitedBackdrop } from '../common/components/LimitedBackdrop/LimitedBackdrop';

function NotesTable({ id, relatedField }) {
  const { data: notes, isSuccess, isError, isLoading } = useGetNotes(id, relatedField);
  console.log('Notes Table Component: ', { notes, isSuccess, isLoading, isError });

  const colspanNumber = relatedField === undefined ? 5 : 4;

  const { data } = useGetCurrentUser();
  const { currentUser } = data || {};

  return (
    <Box style={{ marginTop: relatedField === 'customer' ? '10px' : '0px' }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Note Message</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Created At</TableCell>
              {relatedField === undefined && <TableCell>Related Page</TableCell>}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isError && (
              <TableRow>
                <TableCell colSpan={colspanNumber}>
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    <Typography>Something went wrong!</Typography>
                  </Alert>
                </TableCell>
              </TableRow>
            )}
            {isLoading && (
              <TableRow style={{ position: 'relative', height: '110px' }}>
                <LimitedBackdrop open={isLoading}>
                  <CircularProgress color="primary" />
                </LimitedBackdrop>
              </TableRow>
            )}
            {isSuccess &&
              (notes?.length > 0 ? (
                notes.map(note => {
                  const { firstName, lastName, message, id, restNote } = note;
                  const { createdDate, orderId, elbCardId, customerId, createdBy } = restNote;
                  const relatedFieldCell =
                    orderId !== null
                      ? 'Order'
                      : elbCardId !== null
                      ? 'ELB Card'
                      : customerId !== null
                      ? 'Customer'
                      : '-';

                  const canEditNote =
                    currentUser.role?.name === 'Admin' || currentUser.id === createdBy;

                  return (
                    <TableRow key={id}>
                      <TableCell>{message}</TableCell>
                      <TableCell>{`${firstName} ${lastName}`}</TableCell>
                      <TableCell>{format(new Date(createdDate), 'yyyy-MM-dd HH:mm')}</TableCell>
                      {relatedField === undefined && <TableCell>{relatedFieldCell}</TableCell>}
                      <TableCell>
                        {canEditNote ? (
                          <CreateOrEditNote noteId={id} message={message} isEditing={true} />
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={colspanNumber}>
                    <Alert severity="warning">
                      <AlertTitle>Notes</AlertTitle>
                      <Typography>There is no note to display!</Typography>
                    </Alert>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default NotesTable;
