import React, { useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle, Pagination } from '@material-ui/lab';

import { format } from 'date-fns';
import { useGetPayments } from '../../hooks/useGetPayments';
import { useDebounce } from '../../../common/hooks';
import {
  FileCopyOutlined,
  KeyboardArrowDown,
  KeyboardArrowRight,
  Replay as RefundIcon,
  Remove,
} from '@material-ui/icons';
import PaymentRefundDialog from '../PaymentRefundDialog/PaymentRefundDialog';
import PaymentStatusSelect from '../PaymentStatusSelect';
import { useSnackbar } from '../../../lib/hooks';
import routes from '../../../../utils/routes';
import { LimitedBackdrop } from '../../../common/components/LimitedBackdrop/LimitedBackdrop';

function PaymentsTable() {
  const [searchText, setSearchText] = useState('');
  const [lastSearchTextValue, setLastSearchTextValue] = useState(null);
  const debouncedSearchText = useDebounce(searchText, 500);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(null);
  const [open, setOpen] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [status, setStatus] = useState('');
  const searchTextInputRef = useRef(null);

  const dateTimeFormat = 'yyyy-MM-dd HH:mm';
  const uuidV4Last12DigitsIndex = 36 - 12;

  const values = {
    search: debouncedSearchText,
    page,
    status,
  };

  const { isError, isLoading, isSuccess, data: resultData, refetch } = useGetPayments(values);

  useEffect(() => {
    if (debouncedSearchText) {
      setLastSearchTextValue(debouncedSearchText);
      if (lastSearchTextValue !== debouncedSearchText) {
        if (searchTextInputRef?.current) {
          searchTextInputRef.current.focus();
          searchTextInputRef.current.select();
        }
      }
    }
  }, [debouncedSearchText, lastSearchTextValue]);

  useEffect(() => {
    const updatedNumberOfPages = Math.ceil(resultData?.total / resultData?.pageSize);
    setPages(updatedNumberOfPages);
  }, [resultData]);

  const [expandedRow, setExpandedRow] = useState([]);
  const handleRowClick = index => {
    let newExpandedRowsArray = [];
    const indexIsAlreadyInArray = expandedRow.filter(row => row === index).length > 0;
    if (indexIsAlreadyInArray) {
      newExpandedRowsArray = expandedRow.filter(item => item !== index);
    } else {
      newExpandedRowsArray = [...expandedRow, index];
    }
    setExpandAll(newExpandedRowsArray.length === resultData?.pageSize);
    setExpandedRow(newExpandedRowsArray);
  };

  useEffect(() => {
    if (isSuccess) {
      const defaultExpandedRows = resultData?.data?.map(payment => payment._id) || [];
      setExpandedRow(defaultExpandedRows);
      setExpandAll(defaultExpandedRows.length > 0);
    }
  }, [isSuccess, resultData]);

  const [expandAll, setExpandAll] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [clickedCopyButton, setClickedCopyButton] = useState(false);
  async function copyTextToClipboard(text) {
    setClickedCopyButton(true);
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  useEffect(() => {
    if (clickedCopyButton) {
      enqueueSnackbar('Copied!', 'success', {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      setTimeout(() => {
        setClickedCopyButton(false);
      }, 3000);
    }
  }, [clickedCopyButton, enqueueSnackbar]);

  return (
    <div>
      <Box
        display={'flex'}
        justifyContent={'center'}
        flexWrap={'wrap'}
        alignItems={'center'}
        alignContent={'center'}
      >
        <TextField
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          style={{ margin: '8px', width: '350px' }}
          label="Payment Search"
          type="text"
          inputRef={searchTextInputRef}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
        <PaymentStatusSelect status={status} setStatus={setStatus} isStatistics={false} />
        <Button
          style={{ marginLeft: '8px' }}
          variant="outlined"
          color="primary"
          onClick={() => {
            setSearchText('');
            setLastSearchTextValue(null);
            setStatus('');
          }}
        >
          Clear
        </Button>
        <TableContainer
          component={Paper}
          style={
            {
              /*display: 'flex',*/
              /*justifyContent: 'center',*/
            }
          }
        >
          <Table
            style={{
              minWidth: '650px',
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      setExpandAll(!expandAll);
                      if (expandedRow.length) {
                        setExpandedRow([]);
                      }
                    }}
                  >
                    {expandAll || expandedRow.length === resultData?.pageSize ? (
                      <Remove />
                    ) : (
                      <KeyboardArrowDown />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell>Public payment ID</TableCell>
                <TableCell>Order ID</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell key={Math.random() * 100}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isError && (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Alert severity="error">
                      <AlertTitle>Error</AlertTitle>
                      <Typography>Something went wrong!</Typography>
                    </Alert>
                  </TableCell>
                </TableRow>
              )}
              {isLoading && (
                <TableRow
                  style={{
                    position: 'relative',
                    height: '110px',
                  }}
                >
                  <TableCell colSpan={8}>
                    <LimitedBackdrop open={isLoading} style={{ padding: '8px' }}>
                      <CircularProgress color="primary" />
                    </LimitedBackdrop>
                  </TableCell>
                </TableRow>
              )}
              {isSuccess &&
                (resultData.data?.length > 0 ? (
                  resultData.data.map(payment => {
                    const {
                      _id,
                      amount,
                      currency,
                      customerEmail,
                      customerFamilyName,
                      customerFirstName,
                      publicPaymentId,
                      products,
                      status,
                      cancelledAt,
                      paidAt,
                      refundedAt,
                      timeoutedAt,
                      orderObjectId,
                      orderSeqId,
                      billingCustomerId,
                    } = payment;

                    const humanReadableStatus =
                      status === 'CANCELLED_PAYMENT'
                        ? 'Cancelled'
                        : status === 'PAYMENT_SUCCEEDED'
                        ? 'Paid'
                        : status === 'TRANSACTION_STARTED'
                        ? 'In progress'
                        : status === 'REFUNDED'
                        ? 'Refunded'
                        : status === 'TIMEOUTED'
                        ? 'Timeouted'
                        : 'Unknown state';

                    const billingCustomerFullName = `${customerFirstName} ${customerFamilyName}`;
                    const billingCustomerLink = routes.customer.createLink(
                      billingCustomerId,
                      billingCustomerFullName,
                    );

                    return (
                      <>
                        <TableRow key={_id}>
                          <TableCell>
                            {products && (
                              <IconButton onClick={() => handleRowClick(_id)}>
                                {expandedRow.includes(_id) || expandAll ? (
                                  <KeyboardArrowDown />
                                ) : (
                                  <KeyboardArrowRight />
                                )}
                              </IconButton>
                            )}
                          </TableCell>
                          <TableCell>
                            {routes.payment.createLink(
                              publicPaymentId,
                              publicPaymentId?.slice(uuidV4Last12DigitsIndex),
                            )}
                            <IconButton
                              style={{ padding: '8px' }}
                              size="small"
                              onClick={() => copyTextToClipboard(publicPaymentId)}
                            >
                              <FileCopyOutlined fontSize="small" />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            {orderSeqId
                              ? routes.order.createLink(orderObjectId, `OR${orderSeqId}`)
                              : '-'}
                          </TableCell>
                          <TableCell>{`${amount} ${currency}`}</TableCell>
                          <TableCell>
                            {billingCustomerId ? billingCustomerLink : billingCustomerFullName}
                            {` (${customerEmail})`}
                          </TableCell>
                          <TableCell>{humanReadableStatus}</TableCell>
                          <TableCell>
                            {status === 'CANCELLED_PAYMENT'
                              ? format(new Date(cancelledAt), dateTimeFormat)
                              : status === 'PAYMENT_SUCCEEDED'
                              ? format(new Date(paidAt), dateTimeFormat)
                              : status === 'REFUNDED'
                              ? format(new Date(refundedAt), dateTimeFormat)
                              : status === 'TIMEOUTED'
                              ? format(new Date(timeoutedAt), dateTimeFormat)
                              : '-'}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {status === 'PAYMENT_SUCCEEDED' ? (
                              <RefundIcon
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setOpen(true);
                                  setOrderId(publicPaymentId);
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </TableRow>
                        {products && (
                          <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                              <Collapse in={expandedRow.includes(_id) || expandAll}>
                                <Table size="small">
                                  <TableHead>
                                    <TableCell>Product Name</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Price</TableCell>
                                  </TableHead>
                                  <TableBody>
                                    {products.map(product => {
                                      const { name, quantity, price, productId } = product;
                                      const mapKey = `_id+${name}-${price}+${Math.random()}`;
                                      return (
                                        <TableRow key={mapKey}>
                                          <TableCell>
                                            {routes.product.createLink(productId, name)}
                                          </TableCell>
                                          <TableCell>{quantity}</TableCell>
                                          <TableCell>{`${price} ${currency}`}</TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Alert severity="warning">
                        <AlertTitle>Payments</AlertTitle>
                        <Typography style={{ fontSize: '16px' }}>
                          There is no payment to display!
                        </Typography>
                      </Alert>
                    </TableCell>
                  </TableRow>
                ))}{' '}
            </TableBody>
          </Table>
        </TableContainer>
        {isSuccess && pages > 0 && (
          <Pagination
            style={{ margin: '8px' }}
            color="primary"
            count={pages}
            page={parseInt(resultData?.page)}
            onChange={(_, newPage) => setPage(newPage)}
          />
        )}
        <PaymentRefundDialog
          publicPaymentId={orderId}
          open={open}
          setOpen={setOpen}
          refetch={refetch}
        />
      </Box>
    </div>
  );
}

export default PaymentsTable;
