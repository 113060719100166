import React, { useState } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle, Pagination } from '@material-ui/lab';
import { LimitedBackdrop } from '../../LimitedBackdrop/LimitedBackdrop';

const useGetPurchasedTickets = page => {
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
  return useQuery({
    queryKey: ['purchased-tickets', page],
    queryFn: async () => {
      const response = await axios.get(
        `${REACT_APP_SERVER_URL}dashboard/online-purchased-tickets?page=${page}`,
      );
      const data = await response.data;
      return data;
    },
  });
};

const PurchasedTickets = () => {
  const [page, setPage] = useState(1);

  const { data, isSuccess, isError, isLoading, refetch } = useGetPurchasedTickets(page);
  console.log({ data });
  const { tickets, numberOfTickets } = data || {};
  return (
    <Grid container alignItems="center" spacing={2} style={{padding: '8px'}}>
      <Grid
        xs={12}
        sm={3}
        item
        style={{
          border: '1px solid black',
          height: '100px',
          padding: '8px',
          backgroundColor: 'white',
          borderRadius: '5px',
        }}
      >
        <Typography style={{ fontWeight: '400', fontSize: '18px', marginBottom: '24px' }}>
          Purchased Tickets
        </Typography>
        <Typography style={{ fontWeight: 'bold', fontSize: '60px' }}>
          {isSuccess && numberOfTickets}
        </Typography>
      </Grid>
      <Grid xs={12} sm={9} item>
        <Box style={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
          <Typography variant="body1">Purchased Tickets</Typography>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: '4px' }}
            onClick={refetch}
          >
            Refresh
          </Button>
        </Box>
        <TableContainer
          style={{
            border: '1px solid black',
            backgroundColor: 'white',
            borderRadius: '5px',
            padding: '8px',
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Customer Email</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Event</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Ticket Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isError && (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Alert severity="error">
                      <AlertTitle>ERROR</AlertTitle>
                      <Typography>Something went wrong</Typography>
                    </Alert>
                  </TableCell>
                </TableRow>
              )}
              {isLoading && (
                <TableRow
                  style={{
                    position: 'relative',
                    height: '150px',
                  }}
                >
                  <TableCell colSpan={8}>
                    <LimitedBackdrop open={isLoading}>
                      <CircularProgress color="primary" />
                    </LimitedBackdrop>
                  </TableCell>
                </TableRow>
              )}
              {isSuccess &&
                (tickets.length > 0 ? (
                  tickets.map(ticket => {
                    const {
                      _id,
                      customerEmail,
                      customerFullName,
                      event,
                      status,
                      ticketNumber,
                    } = ticket;
                    return (
                      <TableRow key={_id}>
                        <TableCell>{customerEmail}</TableCell>
                        <TableCell>{customerFullName}</TableCell>
                        <TableCell>{event}</TableCell>
                        <TableCell>{status}</TableCell>
                        <TableCell>{ticketNumber}</TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Alert severity="warning">
                        <AlertTitle>WARNING</AlertTitle>
                        <Typography>No ticket was purchased online in the last week</Typography>
                      </Alert>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Pagination
            style={{ padding: '8px' }}
            color="primary"
            count={Math.ceil(numberOfTickets / 5)}
            page={page}
            onChange={(_, newPage) => setPage(newPage)}
          />
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default PurchasedTickets;
