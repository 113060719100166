import gql from 'graphql-tag';

import { PAGINATION_FRAGMENT } from '../lib/graphql';

export const TRANSACTION_FRAGMENT = gql`
  fragment TransactionFragment on Transaction {
    id
    source {
      id
      balance
      balanceInHuf
      balanceInEur
      name
    }
    target {
      id
      balance
      balanceInHuf
      balanceInEur
      name
    }
    type
    amount
    amountInHuf
    amountInEur
    currency
    description
    createdAt
    transactionSeqId
  }
`;

export const GET_TRANSACTIONS = gql`
  query GetTransactions($options: OptionsInput) {
    transactions(options: $options) @connection(key: "transactions") {
      pagination {
        ...PaginationFragment
      }
      entries {
        ...TransactionFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${TRANSACTION_FRAGMENT}
`;

export const CREATE_TRANSACTION = gql`
  mutation CreateTransaction($data: CreateTransactionInput!) {
    createTransaction(data: $data) {
      ...TransactionFragment
    }
  }
  ${TRANSACTION_FRAGMENT}
`;
