import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { GetApp as DownloadIcon } from '@material-ui/icons';

import { useGetDownloadV2TicketToken } from './useGetDownloadV2TicketToken';

const { REACT_APP_SERVER_URL: SERVER_URL } = process.env;

function DownloadV2Ticket({ id, status }) {
  const { data, mutate, isSuccess } = useGetDownloadV2TicketToken();

  useEffect(() => {
    if (isSuccess) {
      // Trigger download after successful token query
      const { downloadToken: token } = data;
      window.location.href = `${SERVER_URL}download-ticket/${token}`;
    }
  });

  const acceptedStatusToDownload = [
    'Active',
    'FinalizedByOnlinePayment',
    'FinalizedByOfficeEmployee',
  ];
  const canDownloadV2Ticket = acceptedStatusToDownload.includes(status);

  if (!canDownloadV2Ticket) {
    return null;
  }

  return (
    <IconButton
      onClick={() => {
        mutate(id);
      }}
    >
      <DownloadIcon />
    </IconButton>
  );
}

DownloadV2Ticket.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default DownloadV2Ticket;
