import React from 'react';
import {
  CircularProgress,
  createTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { useGetEventProductSales } from '../../hooks';
import { Alert, AlertTitle } from '@material-ui/lab';
import { LimitedBackdrop } from '../../../common/components/LimitedBackdrop/LimitedBackdrop';

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        body: {
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          maxWidth: '100px',
        },
      },
    },
  },
});

function EventProductSales({ eventId }) {
  const { data, isSuccess, isError, isLoading } = useGetEventProductSales(eventId);
  console.log({ data });
  const { ticketsGroupedByEventTicketTypes, ticketsWithoutEventTicketType } = data || {};

  return (
    <ThemeProvider theme={theme}>
      <TableContainer style={{ backgroundColor: 'white', marginTop: '8px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Price (HUF)</TableCell>
              <TableCell align="center">Event Ticket Type</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Limit</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Sold Ticket Number</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Available Ticket Number</TableCell>
              <TableCell align="center">Image</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isError && (
              <TableRow>
                <TableCell colSpan={5}>
                  <Alert severity="error">
                    <AlertTitle>ERROR</AlertTitle>
                    <Typography>Something went wrong</Typography>
                  </Alert>
                </TableCell>
              </TableRow>
            )}
            {isLoading && (
              <TableRow>
                <LimitedBackdrop open={isLoading}>
                  <CircularProgress color="primary" />
                </LimitedBackdrop>
              </TableRow>
            )}
            {isSuccess &&
              ticketsGroupedByEventTicketTypes?.length > 0 &&
              ticketsGroupedByEventTicketTypes.map(groupedTickets => {
                const { tickets } = groupedTickets;
                return tickets.map(eventTicketTypeTicket => {
                  const {
                    _id,
                    availableTicketsCount,
                    paidTickets,
                    ticketLimit,
                    name,
                    description,
                    priceInHuf,
                    imageUrl,
                    eventTicketType,
                  } = eventTicketTypeTicket;
                  const { name: eventTicketTypeName } = eventTicketType?.[0];
                  return (
                    <TableRow key={_id}>
                      <TableCell>{_id}</TableCell>
                      <TableCell>{name}</TableCell>
                      <TableCell>{description}</TableCell>
                      <TableCell>{priceInHuf}</TableCell>
                      <TableCell>{eventTicketTypeName}</TableCell>
                      <TableCell>{ticketLimit}</TableCell>
                      <TableCell>{paidTickets}</TableCell>
                      <TableCell>{availableTicketsCount}</TableCell>
                      {imageUrl && (
                        <TableCell
                          style={{ maxWidth: '400px', borderBottom: '1px solid #eeeeee' }}
                          align="center"
                        >
                          <img src={imageUrl} alt={name} style={{ maxWidth: '60%' }} />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                });
              })}
            {isSuccess &&
              ticketsWithoutEventTicketType?.length > 0 &&
              ticketsWithoutEventTicketType.map(withoutTypeTickets => {
                const { tickets } = withoutTypeTickets;
                return tickets.map(ticketWithoutTicketType => {
                  const {
                    _id,
                    availableTicketsCount,
                    paidTickets,
                    ticketLimit,
                    name,
                    description,
                    priceInHuf,
                    imageUrl,
                  } = ticketWithoutTicketType;
                  return (
                    <TableRow key={_id}>
                      <TableCell>{_id}</TableCell>
                      <TableCell>{name}</TableCell>
                      <TableCell>{description}</TableCell>
                      <TableCell>{priceInHuf}</TableCell>
                      <TableCell>No Event Ticket Type </TableCell>
                      <TableCell>{ticketLimit}</TableCell>
                      <TableCell>{paidTickets}</TableCell>
                      <TableCell>{availableTicketsCount}</TableCell>
                      {imageUrl && (
                        <TableCell
                          style={{
                            maxWidth: '400ox',
                            borderBottom: '1px solid #eeeeee',
                          }}
                          align="center"
                        >
                          <img src={imageUrl} alt={name} style={{ maxWidth: '20%' }} />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                });
              })}
            {isSuccess &&
              ticketsWithoutEventTicketType?.length === 0 &&
              ticketsGroupedByEventTicketTypes?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Alert severity="warning">
                      <AlertTitle>WARNING</AlertTitle>
                      <Typography>There is no available data to display</Typography>
                    </Alert>
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
}

export default EventProductSales;
