import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ProductImageUploader from '../ProductImageUploader/ProductImageUploader.component';
import EventSummary from '../EventSummary';
import { PRODUCTS_API_AXIOS_INSTANCE, PRODUCTS_API_ENDPOINTS } from '../../products.constants';
import { useSnackbar } from '../../../lib/hooks';
import useGetEventTicketTypes from '../../../event-ticket-types/useGetEventTicketTypes';
import { Close } from '@material-ui/icons';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { differenceInDays, isAfter } from 'date-fns';
import useLatestExchangeRate from '../../../exchangeRate/useLatestExchangeRate';
import { useGetAllEventsName } from '../../../events/hooks';
import { Autocomplete } from '@material-ui/lab';

function CreateOrEditProduct({
  error,
  isError,
  isLoading,
  isSuccess,
  mutate,
  eventId,
  initialType,
  isEditing,
  initialProductData,
}) {
  // Selected product image preview
  const [previewImage, setPreviewImage] = useState(null);
  // Store the selected file as base64 for preview and upload
  const [base64File, setBase64File] = useState(null);
  const [, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    name,
    description,
    priceInHuf,
    priceInEur,
    type,
    ticketLimit,
    isWebshopPurchasable,
    includedInOrderEmail,
    isMembershipGranted,
    membershipExpiresInDays,
    needsPostProcessing,
    imageUrl,
    eventId: initialEventId,
    eventTicketTypeId,
    priceInHufWithElbCard,
    priceInEurWithElbCard,
    purchasableFrom,
    purchasableUntil,
    elbDiscount,
    adminFee,
    canCheckingFrom,
    canCheckingUntil,
  } = initialProductData || {};

  const { type: discountType, amount } = elbDiscount ? elbDiscount : {};

  const { fixedAmount, percentage, isRounding } = adminFee || {};

    const [selectedEvent, setSelectedEvent] = useState({});

  const { data: latestExchangeRate } = useLatestExchangeRate();
  const { data: allEventNames } = useGetAllEventsName();
  const customValidateFunction = values => {
    const errors = {};

    if (values.type === 'ticket' && !values.ticketLimit) {
      errors.ticketLimit = 'If Product type is ticket provide ticket limit';
    }
    if (values.discountType && !values.amount) {
      errors.amount = 'If discount type is selected, add the amount of the discount!';
    }
    if (values.amount && (!values.discountType || values.discountType === '')) {
      errors.discountType = 'When discount amount is provided, select the type of the discount';
    }
    if (
      values.purchasableFrom &&
      values.purchasableUntil &&
      differenceInDays(values.purchasableUntil, values.purchasableFrom) < 0
    ) {
      errors.purchasableFrom = 'The from date should be earlier, than the until date!';
    }
    if (values.percentage > 100 || values.percentage < 0) {
      errors.percentage = 'Percentage should be between 0 and 100';
    }

    if (
      !isAfter(new Date(values.canCheckingUntil), new Date(values.canCheckingFrom)) &&
      values.canCheckingUntil !== null
    ) {
      errors.canCheckingFrom = 'The from date should be earlier, than the until date!';
    }
    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: name ? name : '',
      description: description ? description : '',
      priceInHuf: priceInHuf ? priceInHuf : '',
      priceInEur: priceInEur ? priceInEur : '',
      type: initialType ? initialType : type ? type : '',
      ticketLimit: ticketLimit ? ticketLimit : '',
      isWebshopPurchasable: isWebshopPurchasable === true,
      includedInOrderEmail: includedInOrderEmail === true,
      isMembershipGranted: isMembershipGranted === true,
      membershipExpiresInDays: membershipExpiresInDays ? membershipExpiresInDays : undefined,
      needsPostProcessing: needsPostProcessing === true,
      imageUrl: imageUrl || undefined,
      eventId: eventId ? eventId : initialEventId ? initialEventId : undefined,
      priceInHufWithElbCard: priceInHufWithElbCard ? priceInHufWithElbCard : '',
      priceInEurWithElbCard: priceInEurWithElbCard ? priceInEurWithElbCard : '',
      purchasableFrom: purchasableFrom ? purchasableFrom : null,
      purchasableUntil: purchasableUntil ? purchasableUntil : null,
      discountType: discountType ? discountType : '',
      amount: amount ? amount : '',
      eventTicketTypeId: eventTicketTypeId ? eventTicketTypeId : undefined,
      fixedAmount: fixedAmount || '',
      percentage: percentage || '',
      isRounding: isRounding === true,
      canCheckingFrom: canCheckingFrom ? canCheckingFrom : null,
      canCheckingUntil: canCheckingUntil ? canCheckingUntil : null,
    },
    validationSchema: yup.object({
      name: yup.string('Enter the name of the product').required('Product name is required'),
      description: yup
        .string('Add a description for the product')
        .required('Product description is required'),
      priceInHuf: yup
        .number('Enter the priceInHuf of your product')
        .positive()
        .optional(),
      priceInEur: yup
        .number('Enter the priceInEur of your product')
        .positive()
        .optional(),
      type: yup.string().required('Select the type of the product'),
      ticketLimit: yup.number().when('type', {
        is: 'ticket',
        then: yup
          .number()
          .required('If Product has ticket type provide ticket limit')
          .positive(),
      }),
      isWebshopPurchasable: yup.bool().optional(),
      includedInOrderEmail: yup.bool().optional(),
      isMembershipGranted: yup.bool().optional(),
      membershipExpiresInDays: yup.number().when('isMembershipGranted', {
        is: true,
        then: yup
          .number()
          .required('When above checkbox is check you should also add expiration in days.')
          .positive(),
      }),
      imageUrl: yup
        .string()
        .url()
        .nullable()
        .optional(),
      eventId: yup
        .string()
        .nullable()
        .optional(),
      eventTicketTypeId: yup
        .string()
        .nullable()
        .optional(),
      priceInHufWithElbCard: yup
        .number()
        .optional()
        .positive(),
      priceInEurWithElbCard: yup
        .number()
        .optional()
        .positive(),
      purchasableFrom: yup
        .date()
        .nullable()
        .optional(),
      purchasableUntil: yup
        .date()
        .nullable()
        .optional(),
      discountType: yup.string().optional(),
      amount: yup.number().optional(),
      fixedAmount: yup.number().optional(),
      percentage: yup.number().optional(),
      isRounding: yup.bool().optional(),
      canCheckingFrom: yup
        .date()
        .nullable()
        .optional(),
      canCheckingUntil: yup
        .date()
        .nullable()
        .optional(),
    }),
    onSubmit: async (values, actions) => {
      console.log('values before mutation: ', { values });
      const uploadedImageUrl = await uploadImage();
      const latestFormikValues = formik.values;
      const {
        discountType,
        amount,
        fixedAmount,
        percentage,
        isRounding,
        priceInHuf,
        priceInEur,
        priceInHufWithElbCard,
        priceInEurWithElbCard,
        canCheckingFrom,
        canCheckingUntil,
        ...rest
      } = values;

      console.log('Rest values after removing discount related data', { rest });
      console.log('latestFormikValues after uploadImage(): ', { latestFormikValues });

      const customValidation = customValidateFunction(values);
      const lengthOfValidationErrors = Object.keys(customValidation).length;
      console.log({ formik, customValidation, lengthOfValidationErrors });

      const selectedEvent =
        rest.type === 'ticket'
          ? (allEventNames || []).find(event => event.id === rest.eventId)
          : undefined;
      const canCheckingFromDate = getCanCheckingDate(canCheckingFrom, selectedEvent, 'from');
      const canCheckingUntilDate = getCanCheckingDate(canCheckingUntil, selectedEvent, 'until');

      if (lengthOfValidationErrors !== 0 || !formik.isValid) {
        enqueueSnackbar('There was an error during creating/editing the product:!', 'error', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        mutate({
          ...rest,
          imageUrl: uploadedImageUrl,
          priceInHuf: priceInHuf === '' ? null : priceInHuf,
          priceInEur: priceInEur === '' ? null : priceInEur,
          priceInHufWithElbCard: priceInHufWithElbCard === '' ? null : priceInHufWithElbCard,
          priceInEurWithElbCard: priceInEurWithElbCard === '' ? null : priceInEurWithElbCard,
          elbDiscount: {
            type: discountType,
            amount: amount,
          },
          adminFee: {
            fixedAmount,
            percentage,
            isRounding,
          },
          canCheckingFrom: canCheckingFromDate,
          canCheckingUntil: canCheckingUntilDate,
        });
      }
    },
  });

  const { data, isLoading: eventTicketTypeIsLoading } = useGetEventTicketTypes(
    formik.values.eventId,
  );

  const getCanCheckingDate = (date, event, dateType) => {
    console.log({
      date,
      event,
      checkBool: date === null,
      eventCanCheck: event?.canCheckingFrom,
      eventBool: event?.canCheckingUntil === null,
      finaldate:
        date === null
          ? event?.canCheckingUntil === null
            ? new Date(event?.until)
            : new Date(event?.canCheckingUntil)
          : new Date(date),
    });
    if (dateType === 'until') {
      return date === null
        ? event?.canCheckingUntil === null || event?.canCheckingUntil === undefined
          ? new Date(event?.until)
          : new Date(event?.canCheckingUntil)
        : new Date(date);
    } else {
      return date === null
        ? event?.canCheckingFrom === null || event?.canCheckingFrom === undefined
          ? new Date(event?.from)
          : new Date(event?.canCheckingFrom)
        : new Date(date);
    }
  };

  const onImageUploaded = url => {
    console.log('onImageUploaded', { url });
    formik.setFieldValue('imageUrl', url);
    console.log('onImageUploaded', { values: formik.values });
  };

  const uploadImage = async () => {
    try {
      // Upload the image to the Products API
      setIsLoading(true);
      console.log({ base64File });
      const token = localStorage.getItem('token');
      const imageUploadResponse = await PRODUCTS_API_AXIOS_INSTANCE.post(
        `/${PRODUCTS_API_ENDPOINTS.UPLOAD_PRODUCT_IMAGE_ENDPOINT}`,
        {
          base64Image: base64File,
        },
        { headers: { Authorization: token } },
      );
      // Handle image upload response
      if (imageUploadResponse?.data?.url) {
        onImageUploaded(imageUploadResponse.data.url);
        console.log({
          formikImage: formik.values.imageUrl,
          uploadedImage: imageUploadResponse.data.url,
        });
        enqueueSnackbar('Image uploaded!', 'success', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });

        // Return the URL of the uploaded image
        return imageUploadResponse.data.url;
      }
    } catch (error) {
      console.error('uploadImage error', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(
        `An error occured during creating the product: ${error?.response?.data.message}`,
        'error',
        {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 5000,
        },
      );
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Successfully created product', 'success', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 5000,
      });
      formik.resetForm();
    }
  }, [isSuccess]);

  const calculateHufFromEur = eurPrice => {
    const hufPrice = parseFloat(eurPrice * latestExchangeRate?.exchangeRate.toFixed(2));
    formik.setFieldValue('priceInHuf', hufPrice);
  };

  const calculateEurFromHuf = hufPrice => {
    const eurPrice = parseFloat((hufPrice / latestExchangeRate?.exchangeRate).toFixed(2));
    formik.setFieldValue('priceInEur', eurPrice);
  };

  const calculateHufFromEurForElbPrice = eurPrice => {
    const hufPrice = parseFloat(eurPrice * latestExchangeRate?.exchangeRate.toFixed(2));
    formik.setFieldValue('priceInHufWithElbCard', hufPrice);
  };

  const calculateEurFromHufForElbPrice = hufPrice => {
    const eurPrice = parseFloat((hufPrice / latestExchangeRate?.exchangeRate).toFixed(2));
    formik.setFieldValue('priceInEurWithElbCard', eurPrice);
  };
  const calculateAdminFeePreview = () => {
    const shouldRound = formik.values.isRounding;
    const adminFeePreview =
      formik.values.fixedAmount + formik.values.priceInHuf * (formik.values.percentage / 100);
    const finalAdminFeePreview = shouldRound ? Math.round(adminFeePreview) : adminFeePreview;
    return finalAdminFeePreview;
  };
  return (
    <>
      {/* {isLoading && <FillerSpinner />} */}
      <Typography variant="h3">{isEditing ? 'Edit Product' : 'Create Product'}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box display={'flex'} flexDirection={'column'}>
            <Box
              marginBottom={'8px'}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
            >
              {previewImage || imageUrl ? (
                <img src={previewImage || imageUrl} alt="New product" width={'75%'} />
              ) : (
                <Typography variant="body1">No product image</Typography>
              )}
            </Box>
            <Box>
              <ProductImageUploader
                onImagePreviewLoaded={image => setPreviewImage(image)}
                setBase64FileToSave={setBase64File}
                //onImageUploaded={url => formik.setFieldValue('imageUrl', url)}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container direction="column" justifyContent="center" alignItems="flex-start">
              <Grid item>
                <TextField
                  required
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ margin: '8px 0px', alignSelf: 'center' }}
                  id="name"
                  name="name"
                  label="Product Name*"
                  type="text"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item>
                <TextField
                  required
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ margin: '8px 0px', alignSelf: 'center' }}
                  id="description"
                  name="description"
                  label="Product Description*"
                  type="text"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                />
              </Grid>
              <Typography style={{ fontWeight: 'bold' }}>
                Global exchange rate: {latestExchangeRate?.exchangeRate} HUF/EUR
              </Typography>
              <Grid item>
                <TextField
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ margin: '8px 0px', alignSelf: 'center' }}
                  id="priceInHuf"
                  name="priceInHuf"
                  label="Product Price (HUF)"
                  type="number"
                  placeholder="Price in HUF"
                  min="0"
                  value={formik.values.priceInHuf}
                  onChange={formik.handleChange}
                  error={formik.touched.priceInHuf && Boolean(formik.errors.priceInHuf)}
                  helperText={formik.touched.priceInHuf && formik.errors.priceInHuf}
                />
              </Grid>
              <Button
                variant="contained"
                color="primary"
                style={{ width: '206px', margin: '4px' }}
                onClick={() => calculateEurFromHuf(formik.values.priceInHuf)}
                disabled={!formik.values.priceInHuf}
              >
                Calculate EUR from HUF input field
              </Button>
              <Grid item>
                <TextField
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Price in EUR"
                  style={{ margin: '8px 0px', alignSelf: 'center' }}
                  id="priceInEur"
                  name="priceInEur"
                  label="Product Price (EUR)"
                  type="number"
                  min="0"
                  value={formik.values.priceInEur}
                  onChange={formik.handleChange}
                  error={formik.touched.priceInEur && Boolean(formik.errors.priceInEur)}
                  helperText={formik.touched.priceInEur && formik.errors.priceInEur}
                />
              </Grid>
              <Button
                variant="contained"
                color="primary"
                style={{ width: '206px', margin: '4px' }}
                onClick={() => calculateHufFromEur(formik.values.priceInEur)}
                disabled={!formik.values.priceInEur}
              >
                Calculate HUF from EUR input field
              </Button>
              <Grid item>
                <TextField
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ margin: '8px 0px', alignSelf: 'center' }}
                  id="priceInHufWithElbCard"
                  name="priceInHufWithElbCard"
                  label="Product Price with ELB Card (HUF)"
                  type="number"
                  min="0"
                  value={formik.values.priceInHufWithElbCard}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.priceInHufWithElbCard &&
                    Boolean(formik.errors.priceInHufWithElbCard)
                  }
                  helperText={
                    formik.touched.priceInHufWithElbCard && formik.errors.priceInHufWithElbCard
                  }
                />
              </Grid>
              <Button
                variant="contained"
                color="primary"
                style={{ width: '206px', margin: '4px' }}
                onClick={() => calculateEurFromHufForElbPrice(formik.values.priceInHufWithElbCard)}
                disabled={!formik.values.priceInHufWithElbCard}
              >
                Calculate EUR from HUF input field
              </Button>
              <Grid item>
                <TextField
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ margin: '8px 0px', alignSelf: 'center' }}
                  id="priceInEurWithElbCard"
                  name="priceInEurWithElbCard"
                  label="Product Price with ELB Card (EUR)"
                  type="number"
                  min="0"
                  value={formik.values.priceInEurWithElbCard}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.priceInEurWithElbCard &&
                    Boolean(formik.errors.priceInEurWithElbCard)
                  }
                  helperText={
                    formik.touched.priceInEurWithElbCard && formik.errors.priceInEurWithElbCard
                  }
                />
              </Grid>
              <Button
                variant="contained"
                color="primary"
                style={{ width: '206px', margin: '4px' }}
                onClick={() => calculateHufFromEurForElbPrice(formik.values.priceInEurWithElbCard)}
                disabled={!formik.values.priceInEurWithElbCard}
              >
                Calculate HUF from EUR input field
              </Button>
              {!formik.values.priceInHuf ? (
                <Typography style={{ fontWeight: 'bold', color: 'red' }}>
                  Before adding admin fee, provide price
                </Typography>
              ) : (
                <Typography style={{ fontWeight: 'bold' }}>
                  Admin Fee Preview: {calculateAdminFeePreview()}
                </Typography>
              )}

              <Grid item>
                <TextField
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ margin: '8px 0px', alignSelf: 'center' }}
                  id="fixedAmount"
                  name="fixedAmount"
                  label="Admin Fee - Fixed Amount"
                  type="number"
                  min="0"
                  value={formik.values.fixedAmount}
                  onChange={formik.handleChange}
                  error={formik.touched.fixedAmount && Boolean(formik.errors.fixedAmount)}
                  helperText={formik.touched.fixedAmount && formik.errors.fixedAmount}
                />
              </Grid>

              <Grid item>
                <TextField
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ margin: '8px 0px', alignSelf: 'center' }}
                  id="percentage"
                  name="percentage"
                  label="Admin Fee - Percentage (%)"
                  type="number"
                  min="0"
                  value={formik.values.percentage}
                  onChange={formik.handleChange}
                  error={
                    (formik.touched.percentage && Boolean(formik.errors.percentage)) ||
                    Boolean(customValidateFunction(formik.values)?.percentage)
                  }
                  helperText={
                    (formik.touched.priceInEurWithElbCard && formik.errors.priceInEurWithElbCard) ||
                    customValidateFunction(formik.values)?.percentage
                  }
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  style={{ alignSelf: 'center', textAlign: 'left' }}
                  control={
                    <Checkbox
                      checked={formik.values.isRounding}
                      value={formik.values.isRounding}
                      onChange={formik.handleChange}
                      name="isRounding"
                      id="isRounding"
                      color="primary"
                    />
                  }
                  label="Should round admin fee"
                />
              </Grid>
              <Grid item>
                <FormControl
                  variant="outlined"
                  style={{
                    width: '206px',
                    alignSelf: 'center',
                    margin: '8px 0px',
                  }}
                >
                  <InputLabel>Product Type*</InputLabel>
                  <Select
                    id="type"
                    name="type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    disabled={initialType}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    <MenuItem value={'elb-card'}>ELB Card</MenuItem>
                    <MenuItem value={'ticket'}>Ticket</MenuItem>
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: 'red' }}>
                  {formik.touched.type && formik.errors.type}
                </FormHelperText>
              </Grid>
              {formik.values.type === 'ticket' && (
                <Grid item>
                  <TextField
                    required
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ margin: '8px 0px', alignSelf: 'center' }}
                    id="ticketLimit"
                    name="ticketLimit"
                    label="Ticket Limit*"
                    type="number"
                    min="0"
                    value={formik.values.ticketLimit}
                    onChange={formik.handleChange}
                    error={
                      (formik.touched.ticketLimit && Boolean(formik.errors.ticketLimit)) ||
                      (!initialType && customValidateFunction(formik.values)?.ticketLimit)
                    }
                    helperText={
                      (formik.touched.ticketLimit && formik.errors.ticketLimit) ||
                      (!initialType && customValidateFunction(formik.values)?.ticketLimit)
                    }
                  />
                </Grid>
              )}
              {formik.values.type === 'ticket' && (
                <>
                  <Grid item>
                    <KeyboardDateTimePicker
                      inputVariant="outlined"
                      format="yyyy-MM-dd HH:mm"
                      margin="normal"
                      id="canCheckingFrom"
                      name="canCheckingFrom"
                      label="Can Check From date"
                      placeholder="YYYY-MM-DD HH:MM"
                      value={formik.values.canCheckingFrom}
                      onChange={value => {
                        formik.setFieldValue('canCheckingFrom', value);
                      }}
                      style={{ margin: '3px', width: '208px' }}
                      autoOk={true}
                      error={
                        (formik.touched.canCheckingFrom &&
                          Boolean(formik.errors.canCheckingFrom)) ||
                        Boolean(customValidateFunction(formik.values)?.canCheckingFrom)
                      }
                      helperText={
                        (formik.touched.canCheckingFrom && formik.errors.canCheckingFrom) ||
                        customValidateFunction(formik.values)?.canCheckingFrom
                      }
                    />
                  </Grid>
                  <Grid item>
                    <KeyboardDateTimePicker
                      inputVariant="outlined"
                      format="yyyy-MM-dd HH:mm"
                      margin="normal"
                      id="canCheckingUntil"
                      name="canCheckingUntil"
                      label="Can Check Until date"
                      placeholder="YYYY-MM-DD HH:MM"
                      value={formik.values.canCheckingUntil}
                      onChange={value => {
                        formik.setFieldValue('canCheckingUntil', value, true);
                      }}
                      style={{ margin: '3px', width: '208px' }}
                      autoOk={true}
                      error={
                        formik.touched.canCheckingUntil && Boolean(formik.errors.canCheckingUntil)
                      }
                      helperText={formik.touched.canCheckingUntil && formik.errors.canCheckingUntil}
                    />
                  </Grid>
                </>
              )}
              {formik.values.type === 'ticket' && (
                <Grid item>
                  <FormControl
                    variant="outlined"
                    style={{
                      width: '206px',
                      alignSelf: 'center',
                      margin: '8px 0px',
                      display: 'flex',
                    }}
                  >
                    <Autocomplete
                      fullWidth
                      options={allEventNames}
                      getOptionLabel={option => option.name}
                      onChange={(event, value) => {
                        formik.setFieldValue('eventId', value?.id || '');
                      }}
                      disabled={eventId || initialEventId}
                      isOptionEqualToValue={(option, value) => option.id === value}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Event"
                          variant="outlined"
                          error={formik.touched.eventId && Boolean(formik.errors.eventId)}
                          helperText={formik.touched.eventId && formik.errors.eventId}
                        />
                      )}
                      value={
                        (allEventNames || []).find(event => event.id === formik.values.eventId) ||
                        null
                      }
                    />
                  </FormControl>
                </Grid>
              )}
              {formik.values.eventId && (
                <Grid item>
                  <FormControl
                    variant="outlined"
                    style={{
                      width: '206px',
                      alignSelf: 'center',
                      margin: '8px 0px',
                      display: 'flex',
                    }}
                  >
                    <InputLabel>Event Ticket Type</InputLabel>

                    <Select
                      id="eventTicketTypeId"
                      name="eventTicketTypeId"
                      value={formik.values.eventTicketTypeId || ''}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.eventTicketTypeId && Boolean(formik.errors.eventTicketTypeId)
                      }
                      startAdornment={
                        formik.values.eventTicketTypeId && (
                          <InputAdornment style={{ marginRight: '4px' }} position="start">
                            <Close
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                formik.setFieldValue('eventTicketTypeId', '');
                                formik.setTouched({ ...formik.touched, eventTicketTypeId: true });
                              }}
                            />
                          </InputAdornment>
                        )
                      }
                    >
                      <MenuItem value="" disabled></MenuItem>
                      {data?.length > 0 &&
                        data.map(eventTicketType => {
                          return (
                            <MenuItem key={eventTicketType._id} value={eventTicketType._id}>
                              {eventTicketType.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item style={{ marginBottom: '8px' }}>
                <FormControl
                  variant="outlined"
                  style={{
                    width: '206px',
                    display: 'inline-flex',
                    alignSelf: 'center',
                    margin: '8px 0px',
                  }}
                >
                  <InputLabel>Discount Type</InputLabel>
                  <Select
                    id="discountType"
                    name="discountType"
                    value={formik.values.discountType}
                    onChange={formik.handleChange}
                    error={
                      (formik.touched.discountType && Boolean(formik.errors.discountType)) ||
                      Boolean(customValidateFunction(formik.values)?.discountType)
                    }
                  >
                    <MenuItem value="" disabled></MenuItem>
                    <MenuItem value={'FIXED_AMOUNT'}>Fixed amount</MenuItem>
                    <MenuItem value={'PERCENTAGE'}> Percentage (%)</MenuItem>
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: 'red' }}>
                  {(formik.touched.discountType && Boolean(formik.errors.discountType)) ||
                    customValidateFunction(formik.values)?.discountType}
                </FormHelperText>
              </Grid>
              <Grid item>
                <TextField
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ margin: '8px 0px', alignSelf: 'center' }}
                  id="amount"
                  name="amount"
                  label="Discount Amount"
                  type="number"
                  min="0"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  error={
                    (formik.touched.amount && Boolean(formik.errors.amount)) ||
                    Boolean(customValidateFunction(formik.values)?.amount)
                  }
                  helperText={
                    (formik.touched.amount && formik.errors.amount) ||
                    customValidateFunction(formik.values)?.amount
                  }
                />
              </Grid>
              <Grid item>
                <KeyboardDateTimePicker
                  inputVariant="outlined"
                  format="yyyy-MM-dd HH:mm"
                  margin="normal"
                  id="purchasableFrom"
                  name="purchasableFrom"
                  label="Purchasable From date"
                  placeholder="YYYY-MM-DD HH:MM"
                  value={formik.values.purchasableFrom}
                  onChange={value => {
                    formik.setFieldValue('purchasableFrom', value);
                  }}
                  style={{ margin: '3px', width: '208px' }}
                  autoOk={true}
                  error={
                    (formik.touched.purchasableFrom && Boolean(formik.errors.purchasableFrom)) ||
                    Boolean(customValidateFunction(formik.values)?.purchasableFrom)
                  }
                  helperText={
                    (formik.touched.purchasableFrom && formik.errors.purchasableFrom) ||
                    customValidateFunction(formik.values)?.purchasableFrom
                  }
                />
              </Grid>
              <Grid item>
                <KeyboardDateTimePicker
                  inputVariant="outlined"
                  format="yyyy-MM-dd HH:mm"
                  margin="normal"
                  id="purchasableUntil"
                  name="purchasableUntil"
                  label="Purchasable Until date"
                  placeholder="YYYY-MM-DD HH:MM"
                  value={formik.values.purchasableUntil}
                  onChange={value => {
                    formik.setFieldValue('purchasableUntil', value, true);
                  }}
                  style={{ margin: '3px', width: '208px' }}
                  autoOk={true}
                  error={formik.touched.purchasableUntil && Boolean(formik.errors.purchasableUntil)}
                  helperText={formik.touched.purchasableUntil && formik.errors.purchasableUntil}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  style={{ alignSelf: 'center', textAlign: 'left' }}
                  control={
                    <Checkbox
                      checked={formik.values.isWebshopPurchasable}
                      value={formik.values.isWebshopPurchasable}
                      onChange={formik.handleChange}
                      name="isWebshopPurchasable"
                      id="isWebshopPurchasable"
                      color="primary"
                    />
                  }
                  label="Is it purchasable in webshop?"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  style={{ alignSelf: 'center', textAlign: 'left' }}
                  control={
                    <Checkbox
                      checked={formik.values.includedInOrderEmail}
                      value={formik.values.includedInOrderEmail}
                      onChange={formik.handleChange}
                      name="includedInOrderEmail"
                      id="includedInOrderEmail"
                      color="primary"
                    />
                  }
                  label="Should it be included in order email?"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  style={{ alignSelf: 'center', textAlign: 'left' }}
                  control={
                    <Checkbox
                      checked={formik.values.needsPostProcessing}
                      value={formik.values.needsPostProcessing}
                      onChange={formik.handleChange}
                      name="needsPostProcessing"
                      id="needsPostProcessing"
                      color="primary"
                    />
                  }
                  label="Does it need post processing?"
                />
              </Grid>
              <Grid item>
                <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                  <Grid item>
                    <FormControlLabel
                      style={{ alignSelf: 'center', textAlign: 'left' }}
                      control={
                        <Checkbox
                          checked={formik.values.isMembershipGranted}
                          value={formik.values.isMembershipGranted}
                          onChange={formik.handleChange}
                          name="isMembershipGranted"
                          id="isMembershipGranted"
                          color="primary"
                        />
                      }
                      label="Is membership granted with product?"
                    />
                  </Grid>
                  {formik.values.isMembershipGranted && (
                    <Grid item>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ margin: '8px', alignSelf: 'center' }}
                        id="membershipExpiresInDays"
                        name="membershipExpiresInDays"
                        label="Membership expiration in days*"
                        type="number"
                        min="0"
                        value={formik.values.membershipExpiresInDays}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.membershipExpiresInDays &&
                          Boolean(formik.errors.membershipExpiresInDays)
                        }
                        helperText={
                          formik.touched.membershipExpiresInDays &&
                          formik.errors.membershipExpiresInDays
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Box>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={
                      (formik.isValid && !formik.dirty) ||
                      isLoading ||
                      Object.keys(customValidateFunction(formik.values)).length !== 0
                    }
                  >
                    Save
                    {isLoading && <CircularProgress size={18} style={{ marginLeft: '8px' }} />}
                  </Button>
                </Box>
              </Grid>
              <Grid item>
                {isEditing && initialEventId && (
                  <EventSummary eventId={initialEventId} isEditing={true} setSelectedEvent={setSelectedEvent} />
                )}
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateOrEditProduct;
