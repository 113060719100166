import { Box, Card, CardContent, CircularProgress, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useGetPayment } from '../../hooks/useGetPayment';
import { InfoCardRow } from '../../../common/components';

function Payment({
  match: {
    params: { id },
  },
}) {
  const { data: paymentData, isLoading, isSuccess } = useGetPayment(id);
  console.log({ paymentData });

  const rows = [
    {
      label: 'Billing Customer',
      value: `${paymentData?.customerFirstName} ${paymentData?.customerFamilyName}`,
    },
    {
      label: 'Billing Customer Email',
      value: paymentData?.customerEmail,
    },
    {
      label: 'Amount',
      value: paymentData?.amount,
    },
    {
      label: 'Currency',
      value: paymentData?.currency,
    },
    {
      label: 'Status',
      value:
        paymentData?.status === 'CANCELLED_PAYMENT'
          ? 'Cancelled'
          : paymentData?.status === 'PAYMENT_SUCCEEDED'
          ? 'Paid'
          : paymentData?.status === 'TRANSACTION_STARTED'
          ? 'In progress'
          : paymentData?.status === 'REFUNDED'
          ? 'Refunded'
          : paymentData?.status === 'TIMEOUTED'
          ? 'Timeouted'
          : 'Unknown state',
    },
  ];

  return (
    <Box style={{ padding: '8px', margin: '8px' }}>
      {isLoading && <CircularProgress />}
      {isSuccess && (
        <Card>
          <CardContent>
            <Typography variant="h3" component="h1" gutterBottom>
              Payment ID: {paymentData.publicPaymentId}
            </Typography>
            {rows.map(({ label, value }, index) => (
              <Grid item key={index} style={{ margin: '8px' }}>
                <InfoCardRow label={label} value={value} />
              </Grid>
            ))}
            <Typography variant="h4" component="h3" gutterBottom>
              Purchased Products
            </Typography>
            {paymentData.products.map((product, index) => {
              console.log({ product, index });
              const productInfoRows = [
                {
                  label: 'Customer Email',
                  value: product.customerEmail || paymentData.customerEmail,
                },
                {
                  label: 'Product Name',
                  value: product.name,
                },
                {
                  label: 'Price',
                  value: product.price,
                },
              ];
              if (product.eventName) {
                productInfoRows.push(
                  {
                    label: 'Event Name',
                    value: product.eventName,
                  },
                  {
                    label: 'Purchased with ELB Card',
                    value: product.withElbCard ? 'Yes' : 'No',
                  },
                );
              }
              return (
                <React.Fragment key={index}>
                  {index > 0 && <hr style={{ width: '65%' }} />}
                  {productInfoRows.map(({ label, value }, rowIndex) => (
                    <Grid item key={rowIndex} style={{ margin: '8px' }}>
                      <InfoCardRow label={label} value={value} />
                    </Grid>
                  ))}
                </React.Fragment>
              );
            })}
          </CardContent>
        </Card>
      )}
    </Box>
  );
}

export default Payment;
