import React from 'react';
import { useFormikContext } from 'formik';

import { TRANSACTION_TYPES } from '../../../../utils/validationSchemas';

import {
  Fields,
  FormikSelect as FSelect,
  FormikTextField as FTextField,
  FormikFormError as FFormError,
} from '../../../lib/components';
import { FormikAccountAutocomplete as FAccountAutocomplete } from '../../../accounts/components';

const txTypeOptions = Object.entries(TRANSACTION_TYPES).map(([value, label]) => ({
  value,
  label,
}));

function TransactionFields() {
  const { values, setFieldValue, handleChange } = useFormikContext();

  function onTxTypeChange(event) {
    const {
      target: { value: txType },
    } = event;

    if (txType === TRANSACTION_TYPES.Income) {
      setFieldValue('source', '');
    }

    if (txType === TRANSACTION_TYPES.Expense) {
      setFieldValue('target', '');
    }

    handleChange(event);
  }

  return (
    <Fields>
      <FSelect
        name="type"
        options={txTypeOptions}
        onChange={onTxTypeChange}
        required
        label="Transaction Type"
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <FAccountAutocomplete
        name="source"
        disabled={values.type === TRANSACTION_TYPES.Income}
        InputProps={{
          required: true,
          label: 'From',
          variant: 'outlined',
          fullWidth: true,
          margin: 'normal',
        }}
      />

      <FAccountAutocomplete
        name="target"
        disabled={values.type === TRANSACTION_TYPES.Expense}
        InputProps={{
          required: true,
          label: 'To',
          fullWidth: true,
          variant: 'outlined',
          margin: 'normal',
        }}
      />

      <FTextField
        name="amountInHuf"
        type="number"
        inputProps={{ min: 0 }}
        label="Amount (HUF)"
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <FTextField
        name="amountInEur"
        type="number"
        inputProps={{ min: 0 }}
        label="Amount (EUR)"
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <FTextField
        name="description"
        type="text"
        label="Note"
        multiline
        rows="4"
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <FFormError />
    </Fields>
  );
}

export default TransactionFields;
