import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ActiveElbCard from './dashboard-statistics/ActiveElbCard';
import EventsInNextWeek from './dashboard-statistics/EventsInNextWeek';
import { Box, Grid } from '@material-ui/core';
import PurchasedElbCards from './dashboard-statistics/PurchasedElbCards';
import PurchasedTickets from './dashboard-statistics/PurchasedTickets';
import PurchasedTicketCardChart from './dashboard-statistics/PurchasedTicketCardChart';

const useStyles = makeStyles(theme => ({
  dashboard: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
    padding: theme.spacing(1),
  },
}));

function Dashboard() {
  const classes = useStyles();

  //return <div className={classes.dashboard} />;
  return (
    <Box>
      <Grid container alignItems="center" spacing={2} style={{ padding: '8px' }}>
        <ActiveElbCard />
        <EventsInNextWeek />
      </Grid>
      <PurchasedElbCards />
      <PurchasedTickets />
      <PurchasedTicketCardChart />
    </Box>
  );
}

export default Dashboard;
