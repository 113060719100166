import React from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { format } from 'date-fns';
import { LimitedBackdrop } from '../../LimitedBackdrop/LimitedBackdrop';

const useGetEventsInNextWeek = () => {
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
  return useQuery({
    queryKey: ['events-next-week'],
    queryFn: async () => {
      const response = await axios.get(
        `${REACT_APP_SERVER_URL}dashboard/number-of-events-upcoming-week`,
      );
      const data = await response.data;
      return data;
    },
  });
};

const EventsInNextWeek = () => {
  const { data, isSuccess, isError, isLoading, refetch } = useGetEventsInNextWeek();
  return (
    <Grid xs={12} sm={9} item>
      <Box style={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
        <Typography variant="body1">Upcoming Events</Typography>
        <Button variant="contained" color="primary" style={{ marginLeft: '4px' }} onClick={refetch}>
          Refresh
        </Button>
      </Box>
      <TableContainer
        style={{
          border: '1px solid black',
          backgroundColor: 'white',
          borderRadius: '5px',
          padding: '8px',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>From</TableCell>
              <TableCell>Until</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Active Tickets</TableCell>
              <TableCell>Reserved Tickets</TableCell>
              <TableCell>Ticket Types Limit</TableCell>
              <TableCell>All Ticket Limit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isError && (
              <TableRow>
                <TableCell colSpan={8}>
                  <Alert severity="error">
                    <AlertTitle>ERROR</AlertTitle>
                    <Typography>Something went wrong</Typography>
                  </Alert>
                </TableCell>
              </TableRow>
            )}
            {isLoading && (
              <TableRow
                style={{
                  position: 'relative',
                  height: '150px',
                }}
              >
                <TableCell colSpan={8}>
                  <LimitedBackdrop open={isLoading}>
                    <CircularProgress color="primary" />
                  </LimitedBackdrop>
                </TableCell>
              </TableRow>
            )}
            {isSuccess &&
              (data.length > 0 ? (
                data.map(event => {
                  const {
                    _id,
                    activeTickets,
                    reservedTickets,
                    ticketLimitSum,
                    eventTicketLimit,
                    name,
                    from,
                    until,
                    location,
                  } = event;
                  return (
                    <TableRow key={_id}>
                      <TableCell>{name}</TableCell>
                      <TableCell>{format(new Date(from), 'yyyy-MM-dd hh:mm')}</TableCell>
                      <TableCell>{format(new Date(until), 'yyyy-MM-dd hh:mm')}</TableCell>
                      <TableCell>{location}</TableCell>
                      <TableCell>{activeTickets || 0}</TableCell>
                      <TableCell>{reservedTickets || 0}</TableCell>
                      <TableCell>{ticketLimitSum || 0}</TableCell>
                      <TableCell>{eventTicketLimit || 0}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Alert severity="warning">
                      <AlertTitle>WARNING</AlertTitle>
                      <Typography>There is no events happening in the next week</Typography>
                    </Alert>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default EventsInNextWeek;
