import React, { useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';

const useGetPurchasedTicketCard = () => {
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
  return useQuery({
    queryKey: ['purchased-ticket-card'],
    queryFn: async () => {
      const response = await axios.get(
        `${REACT_APP_SERVER_URL}dashboard/purchased-tickets-elb-cards`,
      );
      const data = await response.data;
      console.log({ data });
      return data;
    },
  });
};

function PurchasedTicketCardChart({ eventId }) {
  const { data, isSuccess } = useGetPurchasedTicketCard(eventId);
  const [xAxis, setXAxis] = useState([]);
  const [elbCardDataSet, setElbCardDataSet] = useState([]);
  const [ticketDataSet, setTicketDataSet] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      const xAxisData = data.map(data => {
        return data.date;
      });
      setXAxis(xAxisData);

      const elbCardNumber = data.map(data => {
        return data.elbCardNumber;
      });
      setElbCardDataSet(elbCardNumber);

      const ticketNumber = data.map(data => {
        return data.ticketNumber;
      });
      setTicketDataSet(ticketNumber);
    }
  }, [isSuccess, data, setXAxis, setElbCardDataSet, setTicketDataSet]);

  return (
    <Grid container alignItems="center">
      {isSuccess && (
        <Grid xs={12} item>
          <Bar
            data={{
              labels: xAxis,
              datasets: [
                {
                  label: 'ELB Card Number',
                  data: elbCardDataSet,
                  backgroundColor: '#00B4D8',
                  borderColor: '#00B4D8',
                  borderWidth: 0.5,
                },

                {
                  label: 'Ticket Number',
                  data: ticketDataSet,
                  backgroundColor: '#3f51b5',
                  borderColor: '#3f51b5',
                  borderWidth: 0.5,
                },
              ],
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default PurchasedTicketCardChart;
