import { useQuery } from '@tanstack/react-query';
import { PAYMENTS_API_ENDPOINTS, PAYMENT_API_AXIOS_INSTANCE } from '../payments.constants';


export const useGetPayment = (publicPaymentId) => {
    return useQuery({
        queryKey: ['payment-by-public-payment-id'],
        queryFn: async () => {
            const response = await PAYMENT_API_AXIOS_INSTANCE.get(
                `/${PAYMENTS_API_ENDPOINTS.PAYMENTS_CONTROLLER_BASE_URL}/${publicPaymentId}`,
            );
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
    });
};
