import React from 'react';
import * as PropTypes from 'prop-types';
import { DateTimePicker } from '@material-ui/pickers';

import { DATE_TIME_FORMAT } from '../../../../utils/formatters';
import * as Types from '../../../../utils/propTypes';
import FormikFieldWithError from '../FormikFieldWithError';
import { useFormikContext } from 'formik';
import { addHours, addMinutes, subMinutes } from 'date-fns';

function FormikDateTimePicker({ name, responseErrors = {}, placeholder, variant, ...rest }) {
  const {
    values: { from, until },
    touched,
    setValues
  } = useFormikContext();

  React.useEffect(() => {
    if (name === 'from') {
      const fromAsDate = new Date(from);
      if (from && touched.from) {
        const untilAsDate = addHours(fromAsDate, 1);
        setValues((prevValues) => ({
          ...prevValues,
          until: untilAsDate,
          freeWithElbCardFrom: subMinutes(fromAsDate, 15),
          freeWithElbCardUntil: addMinutes(untilAsDate, 15),
        }));
      }
    }
  }, [from, touched.from, setValues, name]);

  React.useEffect(() => {
    if (name === 'until') {
      const untilAsDate = new Date(until);
      if (until && touched.until) {
        setValues((prevValues) => ({
          ...prevValues,
          freeWithElbCardUntil: addMinutes(untilAsDate, 15),
        }));
      }
    }
  }, [until, touched.until, setValues, name]);

  return (
    <FormikFieldWithError name={name} responseErrors={responseErrors}>
      {({ field, form, hasError, errorMessage }) => (
        <DateTimePicker
          {...field}
          onChange={e => form.setFieldValue(name, e)}
          error={hasError}
          helperText={errorMessage}
          placeholder={placeholder}
          ampm={false}
          inputVariant={variant}
          format={DATE_TIME_FORMAT}
          {...rest}
        />
      )}
    </FormikFieldWithError>
  );
}

FormikDateTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  responseErrors: Types.responseErrors,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
};

export default FormikDateTimePicker;
