import React from 'react';
import { useHistory } from 'react-router-dom';
import { addHours, addMinutes, subMinutes } from 'date-fns';
import { isEmpty, isString } from 'lodash';

import routes from '../../../../utils/routes';
import * as Types from '../../../../utils/propTypes';
import { event as eventSchema } from '../../../../utils/validationSchemas';

import { useCreateEvent, useUpdateEvent } from '../../hooks';

import { ActionButtonWithDialogForm } from '../../../common/components';
import { EventFormFields } from '..';

function MutateEvent({ isTopToolbar = false, event = {}, refetchQueries }) {
  const isNew = isEmpty(event);
  const {
    id,
    name,
    from,
    until,
    location,
    ticketLimit,
    billingAccount,
    form,
    isExternal,
    startSales,
    isFreeWithElbCard,
    freeWithElbCardFrom,
    freeWithElbCardUntil,
    canCheckingFrom,
    canCheckingUntil,
  } = event;

  const history = useHistory();

  const [create] = useCreateEvent(refetchQueries ? { refetchQueries } : undefined);
  const [update] = useUpdateEvent(id);

  const dialogProps = {
    title: `${isNew ? 'Add' : 'Edit'} Event`,
    submitButtonText: `${isNew ? 'Add' : 'Edit'} Event`,
    fields: <EventFormFields create={isNew} isEditing={!isNew} form={form ? form.id : ''} />,
  };

  const mutatedNow = new Date();
  mutatedNow.setMinutes(0);
  mutatedNow.setSeconds(0);
  const mutatedUntil = addHours(mutatedNow, 1);

  const mutatedFreeWithElbCardFrom = isNew ? subMinutes(mutatedNow, 15) : subMinutes(new Date(from), 15);
  const mutatedFreeWithElbCardUntil = isNew ? addMinutes(mutatedUntil, 15) : addMinutes(new Date(until), 15);

  const initialValues = {
    name: name || '',
    from: from || mutatedNow,
    until: until || mutatedUntil,
    location: location || '',
    ticketLimit: ticketLimit || '',
    billingAccount: billingAccount ? billingAccount.id : 'manually',
    form: form ? form.id : '',
    isExternal: isExternal || false,
    startSales: startSales || false,
    isFreeWithElbCard: isFreeWithElbCard || false,
    freeWithElbCardFrom: freeWithElbCardFrom || mutatedFreeWithElbCardFrom,
    freeWithElbCardUntil: freeWithElbCardUntil || mutatedFreeWithElbCardUntil,
    canCheckingFrom: canCheckingFrom || null,
    canCheckingUntil: canCheckingUntil || null,
  };
  async function handleSubmit({
    form: formValue,
    billingAccount: billingAccountValue,
    ...otherValues
  }) {
    const args = {
      formId: isString(formValue) ? formValue : formValue.id,
      billingAccount: billingAccountValue === 'manually' ? null : billingAccountValue,
      ...otherValues,
    };
    if (isNew) {
      const result = await create(args);
      history.replace(routes.event.createUrl(result.data.createEvent.id, result.data.createEvent.name));
    } else {
      await update(args);
    }
  }
  const formikProps = {
    initialValues,
    validationSchema: eventSchema,
    onSubmit: handleSubmit,
  };

  const snackbarProps = {
    successText: `Event has been ${isNew ? 'created' : 'edited'}`,
  };

  const actionButtonProps = {
    variant: isNew ? 'contained' : 'text',
    color: isNew ? 'primary' : 'default',
  };

  return (
    <ActionButtonWithDialogForm
      actionButtonText={`${isNew ? 'Add' : 'Edit'} Event`}
      actionButtonProps={actionButtonProps}
      dialogProps={dialogProps}
      formikProps={formikProps}
      snackbarProps={snackbarProps}
      isTopToolbar={isTopToolbar}
    />
  );
}

MutateEvent.propTypes = {
  refetchQueries: Types.refetchQueries,
  event: Types.event,
};

export default MutateEvent;
